@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay-Regular.woff2') format('woff2'),
       url('../fonts/ClashDisplay-Regular.woff') format('woff'),
       url('../fonts/ClashDisplay-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay-Medium.woff2') format('woff2'),
       url('../fonts/ClashDisplay-Medium.woff') format('woff'),
       url('../fonts/ClashDisplay-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay-Semibold.woff2') format('woff2'),
       url('../fonts/ClashDisplay-Semibold.woff') format('woff'),
       url('../fonts/ClashDisplay-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}

