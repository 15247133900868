/* Old Styles */
.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

.customText {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #075395 49.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");

/* Tailwind Setup */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Appearance Removal for Inputs */
  .input-number-no-appearance {
    appearance: none;
  }

  /* Font Faces */
  @font-face {
    font-family: "Rostave";
    src: url("./assets/fonts/RostavePersonalUse.woff") format("woff");
  }

  @font-face {
    font-family: "Inter";
    src: url("./assets/fonts/inter.ttf") format("truetype");
  }
}

/* Ticket Group Hot Deals */
:root {
  --flame-size: 40;
}

.fire {
  position: relative;
  display: inline-block;
}

.flame {
  position: absolute;
  transform-origin: 70% 70%;
  z-index: 2;
  display: inline-block;
  top: calc(var(--flame-size) * -1px);
}

.hotdeal {
  font-size: calc(var(--flame-size) * 1px);
  display: flex;
  align-items: center;
  margin-top: 28px;
}

.fires {
  position: relative;
  display: inline-block;
}

.flames {
  position: absolute;
  transform-origin: 70% 70%;
  z-index: 2;
  display: inline-block;
  top: calc(var(--flame-size) * -1px);
}

.hotdeals {
  font-size: calc(var(--flame-size) * 1.5px);
  margin-top: 28px;
}

/* New Tailwind Customizations */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
    --radius: 0.5rem;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
